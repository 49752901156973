const themes = {
    'qf-light': {
        '--background-color': '#f5f5f5',
        '--primary-color': 'crimson',
        '--primary-hover-color': 'darkred',
        '--text-color': '#333',
        '--sidebar-bg-color': '#1a1a1a',
        '--sidebar-text-color': 'white',
        '--button-bg-color': 'crimson',
        '--button-hover-color': 'darkred',
        '--popup-bg-color': 'white',
        '--form-border-color': '#ddd',
    },
    'qf-dark': {
        '--background-color': '#1a1a1a',
        '--primary-color': 'crimson',
        '--primary-hover-color': 'darkred',
        '--text-color': 'white',
        '--sidebar-bg-color': '#121212',
        '--sidebar-text-color': '#e0e0e0',
        '--button-bg-color': 'crimson',
        '--button-hover-color': 'darkred',
        '--popup-bg-color': '#2c2c2c',
        '--form-border-color': '#444',
    },
    'bl-light': {
        '--background-color': '#e0f7fa',
        '--primary-color': '#007bb5',
        '--primary-hover-color': '#005f8c',
        '--text-color': '#004d60',
        '--sidebar-bg-color': '#004c7a',
        '--sidebar-text-color': '#ffffff',
        '--button-bg-color': '#007bb5',
        '--button-hover-color': '#005f8c',
        '--popup-bg-color': '#ffffff',
        '--form-border-color': '#aad4e4',
    },
    'bl-dark': {
        '--background-color': '#1e1e1e',
        '--primary-color': '#007bb5',
        '--primary-hover-color': '#005f8c',
        '--text-color': 'white',
        '--sidebar-bg-color': '#121212',
        '--sidebar-text-color': '#e0e0e0',
        '--button-bg-color': '#007bb5',
        '--button-hover-color': '#005f8c',
        '--popup-bg-color': '#2c2c2c',
        '--form-border-color': '#444',
    },
};

export default themes;
