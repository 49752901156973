import React, { createContext, useState, useEffect } from 'react';
import { firestore } from '../utils/firebaseConfig.js';
import { collection, getDocs } from 'firebase/firestore';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    // State for both employee and location data
    const [employeeKpis, setEmployeeKpis] = useState(null);
    const [locationKpis, setLocationKpis] = useState(null);
    const [abstractedNames, setAbstractedNames] = useState(null);
    const [loadingEmployee, setLoadingEmployee] = useState(true);
    const [loadingLocation, setLoadingLocation] = useState(true);
    const [loadingAbstractedNames, setLoadingAbstractedNames] = useState(true);

    const fetchEmployeeKpis = async () => {
        try {
            const querySnapshot = await getDocs(collection(firestore, 'employee_kpis'));
            const data = querySnapshot.docs.map(doc => doc.data());
            setEmployeeKpis(data);
        } catch (error) {
            console.error('Error fetching employee KPIs:', error);
        } finally {
            setLoadingEmployee(false);
        }
    };

    const fetchLocationKpis = async () => {
        try {
            const querySnapshot = await getDocs(collection(firestore, 'location_kpis'));
            const data = querySnapshot.docs.map(doc => doc.data());
            setLocationKpis(data);
        } catch (error) {
            console.error('Error fetching location KPIs:', error);
        } finally {
            setLoadingLocation(false);
        }
    };

    const fetchAbstractedNames = async () => {
        try {
            // Fetch all documents in 'abstractedNames' collection
            const namesCollection = collection(firestore, 'abstractedNames');
            const namesSnapshot = await getDocs(namesCollection);

            // Transform documents into a dictionary format: { "Month Year": { name mappings } }
            const namesData = {};
            namesSnapshot.forEach(doc => {
                namesData[doc.id] = doc.data();
            });

            setAbstractedNames(namesData);
        } catch (error) {
            console.error('Error fetching abstracted names:', error);
        } finally {
            setLoadingAbstractedNames(false);
        }
    };

    // Fetch data on component mount
    useEffect(() => {
        fetchEmployeeKpis();
        fetchLocationKpis();
        fetchAbstractedNames();
    }, []);

    return (
        <DataContext.Provider
            value={{
                employeeKpis,
                locationKpis,
                abstractedNames,
                loadingEmployee,
                loadingLocation,
                loadingAbstractedNames,
            }}
        >
            {
                (loadingEmployee || loadingLocation || loadingAbstractedNames)
                    ? <div>Loading…</div>
                    : children
            }
        </DataContext.Provider>
    );
};
