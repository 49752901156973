import React, { useState, useEffect } from 'react';
import { auth, firestore } from '../utils/firebaseConfig';
import { signOut } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import themes from '../utils/themes';
import '../styles/Frame.css';

const applyTheme = (themeName) => {
    const theme = themes[themeName];
    if (!theme) {
        console.warn(`Theme "${themeName}" not found. Falling back to "qf-light".`);
        applyTheme('qf-light');
        return;
    }
    const root = document.documentElement;
    Object.keys(theme).forEach((key) => {
        root.style.setProperty(key, theme[key]);
    });
};

const Frame = ({ children }) => {
    const [userInfo, setUserInfo] = useState(null);
    const [showRegistration, setShowRegistration] = useState(false);

    // Start true, so on desktop load it’s visible; we’ll hide it if mobile or resizing
    const [showSidebar, setShowSidebar] = useState(true);

    const [tempFirstName, setTempFirstName] = useState('');
    const [tempLastName, setTempLastName] = useState('');
    const [tempContactEmail, setTempContactEmail] = useState('');
    const [selectedTheme, setSelectedTheme] = useState('light');
    const [tempTheme, setTempTheme] = useState('light');
    const navigate = useNavigate();

    useEffect(() => {
        const checkUserProfile = async () => {
            const user = auth.currentUser;
            if (user) {
                const userDocRef = doc(firestore, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                    setUserInfo(userDoc.data());
                } else {
                    setShowRegistration(true);
                }
            }
        };

        const savedTheme = localStorage.getItem('theme') || 'light';
        applyTheme(savedTheme);
        setSelectedTheme(savedTheme);

        checkUserProfile();
    }, []);

    // On mount + resize, hide sidebar if mobile
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setShowSidebar(false);
            } else {
                setShowSidebar(true);
            }
        };

        // Check once on mount
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleThemeChange = (e) => {
        const newTheme = e.target.value;
        setTempTheme(newTheme);
        applyTheme(newTheme);
    };

    const openRegistrationPopup = () => {
        if (userInfo) {
            setTempFirstName(userInfo.firstName || '');
            setTempLastName(userInfo.lastName || '');
            setTempContactEmail(userInfo.contactEmail || '');
        }
        setTempTheme(selectedTheme);
        setShowRegistration(true);
    };

    const handleRegistration = async (e) => {
        e.preventDefault();
        try {
            const user = auth.currentUser;
            if (user) {
                await setDoc(doc(firestore, 'users', user.uid), {
                    firstName: tempFirstName,
                    lastName: tempLastName,
                    contactEmail: tempContactEmail,
                    email: user.email,
                });
                setUserInfo({
                    firstName: tempFirstName,
                    lastName: tempLastName,
                    contactEmail: tempContactEmail,
                });
                setSelectedTheme(tempTheme);
                localStorage.setItem('theme', tempTheme);
                setShowRegistration(false);
            }
        } catch (error) {
            console.error('Error creating user profile:', error.message);
        }
    };

    const handleCancel = () => {
        applyTheme(selectedTheme);
        setShowRegistration(false);
    };

    // Centralize navigation so we can close sidebar after navigating on mobile
    const handleNav = (path) => {
        navigate(path);
        // if mobile, hide sidebar
        if (window.innerWidth <= 768) {
            setShowSidebar(false);
        }
    };

    // Toggling sidebar on mobile
    const toggleSidebar = () => {
        setShowSidebar((prev) => !prev);
    };

    // Optionally keep a sign-out function here if you want to pass it into Sidebar
    const handleLogOut = async () => {
        await signOut(auth);
        navigate('/');
        // Also hide sidebar on mobile
        if (window.innerWidth <= 768) {
            setShowSidebar(false);
        }
    };

    return (
        <div className="frame">
            <button className="menu-button" onClick={toggleSidebar}>
                ☰
            </button>

            <div className={`sidebar-container ${showSidebar ? 'visible' : ''}`}>
                <Sidebar
                    userInfo={userInfo}
                    openRegistrationPopup={openRegistrationPopup}
                    handleNav={handleNav}       // pass in our nav function
                    handleLogOut={handleLogOut} // pass in our logout function
                />
            </div>

            {/* Clicking the content on mobile also hides the sidebar */}
            <div className="content" onClick={() => setShowSidebar(false)}>
                {children}
            </div>

            {showRegistration && (
                <div className="registration-popup">
                    <div className="popup-header">
                        <h2>Edit Your Profile</h2>
                        <button className="close-button" onClick={handleCancel}>
                            ×
                        </button>
                    </div>
                    <form onSubmit={handleRegistration}>
                        <input
                            type="text"
                            placeholder="First Name"
                            value={tempFirstName}
                            onChange={(e) => setTempFirstName(e.target.value)}
                            required
                        />
                        <input
                            type="text"
                            placeholder="Last Name"
                            value={tempLastName}
                            onChange={(e) => setTempLastName(e.target.value)}
                            required
                        />
                        <input
                            type="email"
                            placeholder="Contact Email (Optional)"
                            value={tempContactEmail}
                            onChange={(e) => setTempContactEmail(e.target.value)}
                        />

                        <label htmlFor="theme-select">Select Theme:</label>
                        <select
                            id="theme-select"
                            value={tempTheme}
                            onChange={handleThemeChange}
                        >
                            {Object.keys(themes).map((themeName) => (
                                <option key={themeName} value={themeName}>
                                    {themeName.charAt(0).toUpperCase() + themeName.slice(1)}
                                </option>
                            ))}
                        </select>

                        <button type="submit">Save</button>
                        <button type="button" onClick={handleCancel}>
                            Cancel
                        </button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default Frame;
