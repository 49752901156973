import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../components/DataContext';
import { getAuth } from 'firebase/auth';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../utils/firebaseConfig';
import '../styles/Dashboard.css';

const Dashboard = () => {
    const { employeeKpis, loadingEmployee } = useContext(DataContext);
    const [userFullName, setUserFullName] = useState('');
    const [periods, setPeriods] = useState([]);

    // Fetch the logged-in user's first and last name from "users" collection
    useEffect(() => {
        const fetchUserFullName = async () => {
            const auth = getAuth();
            const user = auth.currentUser;
            if (user) {
                const userEmail = user.email;
                const usersCollection = collection(firestore, 'users');
                const userQuery = query(usersCollection, where('email', '==', userEmail));
                const querySnapshot = await getDocs(userQuery);

                if (!querySnapshot.empty) {
                    const userDoc = querySnapshot.docs[0].data();
                    setUserFullName(`${userDoc.firstName} ${userDoc.lastName}`);
                } else {
                    // Fallback to email if no matching user document found
                    setUserFullName(userEmail);
                }
            }
        };

        fetchUserFullName();
    }, []);

    // Fetch a list of all available week summaries for this user
    useEffect(() => {
        const fetchPeriods = async () => {
            const kpisCollection = collection(firestore, 'employee_kpis');
            const kpisSnapshot = await getDocs(kpisCollection);

            // Filter KPIs down to only those for the current user
            const userKpis = kpisSnapshot.docs
                .map((doc) => doc.data())
                .filter((data) => data.employee === userFullName && data.week_summary);

            // Extract unique week_summary values
            const weekSummaries = Array.from(new Set(userKpis.map((item) => item.week_summary)));

            // Sort in descending order (newest first)
            weekSummaries.sort((a, b) => {
                const dateA = new Date(a.match(/\[(\d{1,2}), (\d{1,2}), (\d{4})\]/).slice(1).join('-'));
                const dateB = new Date(b.match(/\[(\d{1,2}), (\d{1,2}), (\d{4})\]/).slice(1).join('-'));
                return dateB - dateA;
            });

            setPeriods(weekSummaries);
        };

        if (userFullName) {
            fetchPeriods();
        }
    }, [userFullName]);

    // Helper to label each card more readably:
    const getWeekLabel = (weekString) => {
        // Remove the "Period: " prefix
        const cleanedString = weekString.replace("Period: ", "");

        // Extract the dates using regex
        const dateRegex = /\[(\d+), (\d+), (\d+)\] to \[(\d+), (\d+), (\d+)\]/;
        const match = cleanedString.match(dateRegex);

        if (match) {
            const [, month1, day1, year1, month2, day2, year2] = match;

            // Format dates to MM.DD.YY
            const formattedStartDate = `${month1.padStart(2, '0')}.${day1.padStart(2, '0')}.${year1.slice(-2)}`;
            const formattedEndDate = `${month2.padStart(2, '0')}.${day2.padStart(2, '0')}.${year2.slice(-2)}`;

            return `${formattedStartDate} - ${formattedEndDate}`;
        }

        // Return original string if regex doesn't match
        return cleanedString;
    };

    // Render each week as a separate “card”
    const renderWeekCard = (week, index) => {
        // Find KPI for this user + this week
        const kpi = employeeKpis?.find(
            (item) => item.employee === userFullName && item.week_summary === week
        );

        if (!kpi) {
            return (
                <div className="week-summary-card" key={week}>
                    <h2>{getWeekLabel(index)}: {week}</h2>
                    <p>No data available.</p>
                </div>
            );
        }

        // Basic calculations:
        const hours = kpi.hours ?? 0;
        const sales = kpi.sales ?? 0;
        const ratio = hours > 0 ? (sales / hours) * 100 : 0;

        // Determine tier label
        let tierLabel = 'N/A';
        if (ratio >= 100) {
            tierLabel = 'Six';
        } else if (ratio >= 80) {
            tierLabel = 'Five';
        } else if (ratio >= 60) {
            tierLabel = 'Four';
        } else if (ratio >= 40) {
            tierLabel = 'Three';
        } else if (ratio >= 20) {
            tierLabel = 'Two';
        } else if (ratio >= 0) {
            tierLabel = 'One';
        }

        return (
            <div className="week-summary-card" key={week}>
                <h2>{getWeekLabel(week)}</h2>
                <p>
                    <strong>Tier:</strong> {tierLabel} ( {ratio.toFixed(2)} )
                </p>
                <p>
                    <strong>Total GP:</strong>{' '}
                    {kpi?.total_gp !== undefined ? `$${kpi.total_gp.toFixed(2)}` : 'N/A'}
                </p>
                <p>
                    <strong>GP / Hour:</strong>{' '}
                    {kpi?.gp_per_hour !== undefined ? `$${kpi.gp_per_hour.toFixed(2)}` : 'N/A'}
                </p>
                <p>
                    <strong>Estimated Commission:</strong>{' '}
                    {kpi?.est_commission !== undefined ? `$${kpi.est_commission.toFixed(2)}` : 'N/A'}
                </p>
                <p>
                    <strong>Sales:</strong> {sales > 0 ? `${sales}` : 'N/A'}
                </p>
                <p>
                    <strong>Repairs:</strong> {kpi.repairs ?? 'N/A'}
                </p>
                <p>
                    <strong>Hours Worked:</strong> {hours > 0 ? `${hours.toFixed(1)} hrs` : 'N/A'}
                </p>
            </div>
        );
    };

    return (
        <div className="dashboard-container">
            <h1>Welcome, {userFullName}</h1>
            <h3>Your Weekly Performance</h3>
            {loadingEmployee ? (
                <p>Loading Employee Data...</p>
            ) : (
                <>
                    {periods.length === 0 ? (
                        <p>No weekly KPI data found.</p>
                    ) : (
                        <div className="week-carousel">
                            {periods.map((week, index) => renderWeekCard(week, index))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Dashboard;
